import React from "react"
import { Link } from "gatsby"

import Logo from "../../assets/images/logo_new.png"
import WLogo from "../../assets/images/Wlogo.svg"

const Navbar = () => {
  const [menu, setMenu] = React.useState(true)
  const [isWhiteLogo, setIsWhiteLogo] = React.useState(true)

  const toggleNavbar = () => {
    setMenu(!menu)
  }


  React.useEffect(() => {
    if (typeof document !== `undefined` && typeof window !== `undefined`) {
      let elementId = document.getElementById("header")
      if (
        (window.location.pathname === "/home" ||
          window.location.pathname === "/") &&
        window.innerWidth > 100

      ) {
        document.addEventListener("scroll", () => {
          if (window.scrollY > 100) {
            setIsWhiteLogo(false)
            elementId.classList.add("is-sticky")
          } else {
            setIsWhiteLogo(true)
            elementId.classList.remove("is-sticky")
          }
          if (window.scrollY > 100) {
            setIsWhiteLogo(false)
            elementId.classList.add("is-nonsticky")
          } else {
            setIsWhiteLogo(true)
            elementId.classList.remove("is-nonsticky")
          }
        })
        window.scrollTo(0, 0)
      } else {
        if (
          window.location.pathname === "/portfolio/" ||
          window.location.pathname === "/portfolio" ||
          window.location.pathname === "/ourServices/" ||
          window.location.pathname === "/ourServices" ||
          window.location.pathname === "/contact/" ||
          window.location.pathname === "/contact"
        ) {
          elementId.classList.add("is-sticky")
          setIsWhiteLogo(false)
        } else if (window.innerWidth < 100) {
          let el = document.getElementsByClassName("navbar-toggler-right")
          el[0].style.display = "none"
          document.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
              setIsWhiteLogo(false)
              elementId.classList.add("is-sticky")
              el[0].style.display = "block"
            } else {
              setIsWhiteLogo(true)
              elementId.classList.remove("is-sticky")
              el[0].style.display = "none"
            }
          })
        }
        // setIsWhiteLogo(false)
        // let elementId2 = document.getElementById("mobmenu")
        // document.addEventListener("scroll", () => {
        //   if (window.scrollY > 170) {
        //     elementId2.classList.remove("mobmenuc")
        //   } else {
        //     elementId2.classList.add("mobmenuc")
        //   }
        // })
      }
    }

  }, [])

  const classOne = menu
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show"
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right"

  return (
    <header id="header" className="headroom">
      <a href="tel:9999999999" style={{ display: "none" }}></a>
      <div className="startp-nav">
        <div className="container">
          {isWhiteLogo
            ? <nav className="navbar navbar-expand-md navbar-light d-flex justify-content-center">
              <Link to="/">
                <img src={WLogo} alt="logo" style={{ width: 120 }} />
              </Link>
            </nav>
            : <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/">
                <img src={Logo} alt="logo" style={{ width: "110px" }} />
              </Link>
              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                id="mobmenu"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto" style={{ marginTop: "-1" }}>
                  <li className={"nav-item "}>
                    <Link
                      to="/"
                      activeClassName="active"
                      onClick={toggleNavbar}
                      id="link"
                      className={(isWhiteLogo && "is-nonsticky", "nav-link")}
                    >
                      Home
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/portfolio"
                      activeClassName="active"
                      onClick={toggleNavbar}
                      className={(isWhiteLogo && "is-nonsticky", "nav-link")}
                    >
                      Portfolio
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/ourServices"
                      activeClassName="active"
                      onClick={toggleNavbar}
                      className={(isWhiteLogo && "is-nonsticky", "nav-link")}
                    >
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/contact"
                      activeClassName="active"
                      onClick={toggleNavbar}
                      id="link"
                      className="nav-link"
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          }
        </div>
      </div>
    </header>
  )
}

export default Navbar
