// const Footer = () => {
//   return (
//     <div class="d-flex flex-column h-100">
//     <footer class="w-100 py-4 flex-shrink-0">
//         <div class="container py-4">
//             <div class="row gy-4 gx-5">
//                 <div class="col-lg-4 col-md-6">
//                     <h5 class="h1 text-white">FB.</h5>
//                     <p class="small text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
//                     <p class="small text-muted mb-0">&copy; Copyrights. All rights reserved. <a class="text-primary" href="#">Bootstrapious.com</a></p>
//                 </div>
//                 <div class="col-lg-4 col-md-6">
//                     <h5 class="text-white mb-3">Newsletter</h5>
//                     <p class="small text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
//                     <form action="#">
//                         <div class="input-group mb-3">
//                             <input class="form-control" type="text" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="button-addon2"/>
//                             <button class="btn btn-primary" id="button-addon2" type="button"><i class="fas fa-paper-plane"></i></button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     </footer>
// </div>
//   )
// }

// export default Footer

import React from "react"
import { Link } from "gatsby"
import * as Icon from "react-feather"

import Logo from "../../assets/images/logo.svg"
import MapImg from "../../assets/images/map.png"
import Shape1 from "../../assets/images/shape1.png"
import Shape2 from "../../assets/images/shape2.svg"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer-area bg-f7fafd">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-sm-8">
            <div className="single-footer-widget">
              <div className="logo">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="logo"
                    style={{
                      maxWidth: "4rem",
                      marginLeft: "0.9rem",
                      marginTop: "-1rem",
                    }}
                  />
                </Link>
              </div>
              <p className="FooWidth" style={{ marginLeft: 15 }}>
                We are working on a problem, never think about beauty.<br></br>
                We only think how to solve the problem.<br></br>
                But when we have finished, if the solution is not beautiful,<br></br>
                We know it is wrong.
              </p>

            </div>
          </div>

          <div className="col-lg-4 col-sm-4 d-flex justify-content-center align-items-center">
            <Link to="/contact" className="btn btn-primary">
              Contact Us
            </Link>
            <div className="single-footer-widget">

              {/* <h3>Address</h3>
              <ul className="footer-contact-info">
                <li>
                  <Icon.MapPin />
                  306, Princes' Business Skyline, <br></br>
                  Vijay Nagar, Indore M.P. India
                </li>
                <li>
                  <Icon.Mail />
                  sales@sanswebsolutions.com,<br></br>
                  info@sanswebsolutions.com
                </li>
                <li>
                  <Icon.PhoneCall />
                  <Link tel="+91 9584860344"> +91 9584860344, </Link><br></br>
                  <span>
                    <Link tel="+1904 748 9902"> +1904 748 9902</Link>
                  </span>
                </li>
              </ul> */}
            </div>
          </div>


          <div className="col-lg-12 col-md-12">
            <div className="copyright-area">
              <p>
                Copyright &copy; {currentYear} All rights reserved by Sans Web
                Solutions
              </p>
            </div>
          </div>
        </div>
      </div>

      <img src={MapImg} className="map" alt="map" />

      {/* Shape Images */}
      <div className="shape1">
        <img src={Shape1} alt="shape" />
      </div>
      <div className="shape8 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
    </footer>
  )
}

export default Footer
